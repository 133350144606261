export const useDialogStore = defineStore('dialog', {
  state: () => {
    return {
      settings: {
        isOpen: false,
        currentTab: 'personal',
      },
      passwordChange: {
        isOpen: false,
      },
      userInvite: {
        isOpen: false,
        onSubmit: () => {},
      },
      workspaceAirtable: {
        isOpen: false,
        onSubmit: () => {},
      },
      workspaceAttenduApiKeys: {
        isOpen: false,
        onSubmit: () => {},
      },
      workspaceCustomDomainAdd: {
        isOpen: false,
        onSubmit: () => {},
      },
      workspaceCustomDomainSettings: {
        isOpen: false,
        onSubmit: () => {},
        onClose: () => {},
      },
      planUpgrade: {
        isOpen: false,
        plan: null,
        onSubmit: () => {},
      },
      planOrder: {
        isOpen: false,
        tier: null,
        currentPlan: null,
        onSubmit: () => {},
      },
      eventCreate: {
        isOpen: false,
      },
      guestCreate: {
        isOpen: false,
        guestsRefresh: () => {},
      },
      guestEdit: {
        isOpen: false,
        guestId: null,
        guestsRefresh: () => {},
      },
      guestConfirmation: {
        isOpen: false,
        guest: null,
        onSubmit: () => {},
      },
      guestsColumns: {
        isOpen: false,
        onSubmit: () => {},
      },
      guestSendMailing: {
        isOpen: false,
        guest: null,
      },
      guestsImportExcel: {
        isOpen: false,
        guestsRefresh: () => {},
      },
      guestsImportAirtable: {
        isOpen: false,
        guestsRefresh: () => {},
      },
      guestsImportPairOptions: {
        isOpen: false,
        row: null,
        onSubmit: () => {},
      },
      mailingCreate: {
        isOpen: false,
      },
      mailingPreview: {
        isOpen: false,
        mailingId: null,
      },
      mailingEditParams: {
        isOpen: false,
        template: null,
        onSubmit: () => {},
      },
      templateSettings: {
        isOpen: false,
        template: null,
        onSubmit: () => {},
      },
      templateSelect: {
        isOpen: false,
        type: null,
        currentTab: 'all',
        replaceTemplate: null,
        allowedCategories: [],
        onSubmit: () => {},
      },
      templatePresetPreview: {
        isOpen: false,
        preset: null,
        template: null,
        type: null,
        categoryTitle: '',
        replaceTemplate: null,
      },
      templateRename: {
        isOpen: false,
        template: null,
        onSubmit: () => {},
      },
      templatePageLinks: {
        isOpen: false,
        template: null,
      },
      templateInitialize: {
        isOpen: false,
        template: null,
        allowedCategories: [],
      },
      builderSections: {
        isOpen: false,
        template: null,
        currentTab: 'header',
        onAddSection: () => {},
      },
      builderMobile: {
        isOpen: false,
        type: '',
      },
      confirm: {
        isOpen: false,
        title: null,
        description: null,
        icon: null,
        confirmButtonType: null,
        confirmButtonText: null,
        onConfirm: () => {},
      },
      eventCollaboratorsAdd: {
        isOpen: false,
        onSubmit: () => {},
      },
      eventCheckInZone: {
        isOpen: false,
        zone: null,
        type: 'create',
        onSubmit: () => {},
      },
      eventStages: {
        isOpen: false,
      },
      guestAppActivation: {
        isOpen: false,
        item: null,
      },
      newsItemCreate: {
        isOpen: false,
        onSubmit: () => {},
      },
      newsItemEdit: {
        isOpen: false,
        item: null,
        onSubmit: () => {},
      },
      agendaItemCreate: {
        isOpen: false,
      },
      agendaItemEdit: {
        isOpen: false,
        item: null,
      },
      sponsorItemCreate: {
        isOpen: false,
      },
      sponsorItemEdit: {
        isOpen: false,
        item: null,
      },
      speakerItemCreate: {
        isOpen: false,
      },
      speakerItemEdit: {
        isOpen: false,
        item: null,
      },
      helpdeskItemCreate: {
        isOpen: false,
      },
      helpdeskItemEdit: {
        isOpen: false,
        item: null,
      },
      eventSponsorGroups: {
        isOpen: false,
      },
      feedbackQuestionsItemCreate: {
        isOpen: false,
      },
      feedbackQuestionsItemEdit: {
        isOpen: false,
      },
      feedbackQuestionsEdit: {
        isOpen: false,
      },
      feedbackSurveyQuestion: {
        isOpen: false,
        question: null,
        onSubmit: () => {},
      },
      feedbackSurveyQuestions: {
        isOpen: false,
      },
      otherAboutEdit: {
        isOpen: false,
      },
      loading: {
        isOpen: false,
        title: '',
        description: '',
      },
      paymentResponseSuccess: {
        isOpen: false,
        persistent: false,
      },

      itemImport: {
        isOpen: false,
        title: '',
        countName: '',
        itemName: '',
        onSubmit: () => {},
      },

      appGuestDetails: {
        isOpen: false,
      },
      appHelpdesk: {
        isOpen: false,
      },
      appAgenda: {
        isOpen: false,
      },
      appIntro: {
        isOpen: false,
      },
    };
  },

  actions: {
    open(dialogName, payload = {}) {
      this[dialogName].isOpen = true;
      this[dialogName] = {
        ...this[dialogName],
        onSubmit: () => {},
        ...payload,
      };
    },

    close(dialogName) {
      this[dialogName].isOpen = false;
    },
  },
});
