export const useWorkspaceStore = defineStore('workspace', {
  state: () => {
    return {
      domain: null,
      name: null,
      identity: {
        primaryColor: null,
        logo: null,
        font: null,
      },
      stripe: {
        isCzechAccount: false,
      },
      globalTemplates: {
        email: [],
        page: [],
      },
      integrations: {},
      isLoaded: false,
      loadFailed: false,
    };
  },

  actions: {
    // Workspace
    async fetchWorkspace() {
      const workspace = await useApi.get('/public/workspace').catch(() => {
        useToast.error(useT('abc242'));
        this.loadFailed = true;
      });

      if (workspace) {
        this.domain = workspace.domain;
        this.name = workspace.name;
        this.identity = workspace.identity;
        this.globalTemplates = workspace.globalTemplates;
        this.integrations = workspace.integrations;
        this.stripe = workspace.stripe;
        this.loadFailed = false;

        return workspace;
      }

      return false;
    },

    async getGlobalTemplates(type) {
      const response = await useApi.get('/workspace/global-templates', {
        params: {
          type,
        },
      }).catch(() => {
        useToast.error(useT('abc242'));
      });

      return response;
    },

    async updateWorkspace(body) {
      const response = await useApi.put('/workspace', { body }).catch(() => {
        useToast.error(useT('abc267'));
      });

      if (response) {
        Object.entries(body)?.forEach(([key, value]) => {
          this[key] = value;
        });
      }

      return response;
    },

    // Workspace/Airtable
    async getAirtable() {
      const response = await useApi.get('/workspace/airtable').catch(() => {
        useToast.error(useT('abc242'));
      });

      return response;
    },

    async updateAirtable(body) {
      const response = await useApi.put('/workspace/airtable', { body }).catch(() => {
        useToast.error(useT('abc267'));
      });

      return response;
    },
    async getAirtableTables({ apiKey, baseId }) { // for integration settings
      const response = await useApi.get('/workspace/airtable/tables', {
        headers: {
          'x-airtable-api-key': apiKey,
          'x-airtable-base-id': baseId,
        },
      }).catch(() => {
        useToast.error(useT('abc242'));
      });

      return response;
    },

    async getAirtableTable() {
      const response = await useApi.get('/workspace/airtable/table').catch(() => {
        useToast.error(useT('abc242'));
      });

      return response;
    },
    async getAirtableTableView(viewId) {
      const response = await useApi.get(`/workspace/airtable/table/views/${viewId}`).catch(() => {
        useToast.error(useT('abc242'));
      });

      return response;
    },

    // Workspace/CustomDomain
    async addCustomDomain(body) {
      const response = await useApi.post('/workspace/custom-domain', { body }).catch((error) => {
        if (error.response?.status === 400) {
          useToast.error(useT('abc269'));
        } else if (error.response?.status === 409) {
          useToast.error(useT('abc270'));
        } else {
          useToast.error(useT('abc267'));
        }
      });

      return response;
    },
    async checkCustomDomain() {
      const response = await useApi.get('/workspace/custom-domain/check').catch(() => {
        useToast.error(useT('abc271'));
      });

      return response;
    },
    async removeCustomDomain() {
      const response = await useApi.delete('/workspace/custom-domain').catch(() => {
        useToast.error(useT('abc272'));
      });

      return response;
    },

    // Workspace/ApiKeys
    async getApiKeys() {
      const response = await useApi.get('/workspace/api-keys').catch(() => {
        useToast.error(useT('abc242'));
      });

      return response;
    },
    async createApiKey() {
      const response = await useApi.post('/workspace/api-keys').catch(() => {
        useToast.error(useT('abc267'));
      });

      return response;
    },
    async deleteApiKey(id) {
      const response = await useApi.delete(`/workspace/api-keys/${id}`).catch(() => {
        useToast.error(useT('abc267'));
      });

      return response;
    },

    // Workspace/GlobalTemplates
    async addGlobalTemplate(payload) {
      const response = await useApi.post('/workspace/global-templates', {
        body: payload,
      }).catch(() => {
        useToast.error(useT('abc273'));
      });

      if (response) {
        this.globalTemplates = response.globalTemplates;
      }

      return response;
    },

    async removeGlobalTemplate(payload) {
      const response = await useApi.delete('/workspace/global-templates', {
        body: payload,
      }).catch(() => {
        useToast.error(useT('abc274'));
      });

      if (response) {
        this.globalTemplates = response.globalTemplates;
      }

      return response;
    },

    // Workspace/Stripe
    async openCustomerPortal() {
      useDialogStore().open('loading', {});
      const response = await useApi.get('/workspace/customer-portal').catch(() => {
        useToast.error(useT('xyz78'));
      });

      if (response) {
        window.location.href = response.url;
      }
    },
  },

  getters: {
    getCustomOrigin: (state) => {
      return (event) => {
        if (state.integrations?.customDomain?.url && event?.plan?.config?.tier === 'enterprise' && useRuntimeConfig().public.nodeEnv === 'production') {
          return `https://${state.integrations.customDomain.url}`;
        }

        return useRequestURL().origin;
      };
    },

    getCurrency: (state) => {
      return state.stripe?.isCzechAccount ? 'czk' : 'usd';
    },
  },
});
